<template lang="html">
  <div class="KeyFrames">
    <DragingEvent class="FrameItem" v-for="(f,index) in frames" :style="getFstyle(f,index)" :stop="true" @click="onClick($event,f,index)" @moveing="onMoveing($event,f,index)" @moveEnd="onMoveEnd($event,f,index)">
      <template v-if="frameIndex==index">
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path fill="#ff0000" d="m3.72072,8.22143l4.27928,-6.00921l4.27928,6.00921l-4.27928,6.00921l-4.27928,-6.00921z" id="svg_7" stroke="#ffffff" stroke-width="1.5" />
          </g>
        </svg>
      </template>
      <template v-else>
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path stroke-opacity="0" fill="#000000" d="m3.72072,8.22143l4.27928,-6.00921l4.27928,6.00921l-4.27928,6.00921l-4.27928,-6.00921z" id="svg_7" stroke="#ffffff" stroke-width="1.5" />
          </g>
        </svg>
      </template>
    </DragingEvent>
  </div>
</template>

<script>
  import DragingEvent from './DragingEvent.vue'
  export default {
    components: {
      DragingEvent,
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        required: true
      },
      frameIndex: {
        default: null
      },
      option: {
        default() {
          return {}
        }
      },
    },
    mounted() {
    },
    data() {
      return {
      }
    },
    computed: {
      frames() {
        return this.value.keyFrames
      }
    },
    watch: {
      value(val, oldval) {
      }
    },
    methods: {
      onClick(e, f, index) {
        console.log('kfc')
        this.$emit('frameClick', f, index)
      },
      onMoveing({ start, last, now }, f, index) {
        var vw = this.$el.offsetWidth
        var l = this.value.duration
        var dx = ((now.x - last.x) / vw) * l
        f.time += dx
        if (f.time < 0) {
          f.time = 0
        }
        if (f.time > l) {
          f.time = l
        }
        f.time = Number(f.time.toFixed(0))
        this.$emit('frameMove', dx, f, index)
      },
      onMoveEnd({ start, last, now }, f, index) {
        this.$emit('frameMoveEnd', f, index)
      },
      getFstyle(f, index) {
        var l = this.value.duration
        return {
          left: `${(f.time / l) * 100}%`,
          'z-index': index == this.frameIndex ? 1 : 0
        }
      }
    },
  }
</script>

<style lang="css" scoped>
  .KeyFrames {
    width: 100%;
    height: 100%;
    position: relative
  }

  .FrameItem {
    position: absolute;
    transform: translateX(-7px);
  }
</style>
