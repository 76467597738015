<template lang="html">
  <DragingEvent class="lineBar" @moveing="lineMoveing" @click="lineClick" @mousewheel="lineWheel">
    <div class="ruler" :style="getRulerStyle(ruler) " v-if="ruler">
      <div v-for="i in rulerMarts" :style="getRulerMartStyle(i)" class="item">
        <slot name="ruler" :data="i" :step="rulerStep">{{i}}</slot>
      </div>
    </div>
    <!--<div v-for="r in viewData" class="layer" :style="getLayerStyle(r)">
      <div v-for="l in r.levels" :style="getLevelStyle(l,r)" class="item" @mousedown.prevent="itemDown($event,l,r)" @mousemove.prevent="itemMove($event,l,r)" @mouseup.prevent="itemUp($event,l,r)" @touchstart.prevent="itemDown($event,l,r)" @touchmove.prevent="itemMove($event,l,r)" @touchend.prevent="itemUp($event,l,r)">
        <slot :data="l.data" :level="l" :itemDragStart="itemDragStart" :itemDarging="itemDarging" :itemDargEnd="itemDargEnd" :draging="itemDrag == l">
          <span>{{l.index}}</span>
        </slot>
      </div>
    </div>-->
    <div class="layer" :style="getLayerStyle(sumData)">
      <div v-for="(r,index) in rowData" class="item" style="" :style="getRowBackStyle(r)">
        <slot name="rowBack" :data="r" :index="index">
          <div style="font-size:12px;color:#fff8;overflow:hidden;height:100%;">{{r.setting.name}}</div>
        </slot>
      </div>
      <template v-for="l in viewData">
        <template v-for="r in [rowData[l.rowGuid]]">
          <!--@mousedown.prevent="itemDown($event,l,r)" @mousemove.prevent="itemMove($event,l,r)" @mouseup.prevent="itemUp($event,l,r)"-->
          <DragingEvent :style="getLevelStyle(l,r)" class="item" :stop="true" @click="itemClick($event,l,r)" @moveStart="itemMoveStart($event,l,r)" @moveing="itemMoveing($event,l,r)" @moveEnd="itemMoveEnd($event,l,r)">
            <slot :data="l.data" :level="l" :itemDragStart="itemDragStart" :itemDarging="itemDarging" :itemDargEnd="itemDargEnd" :draging="itemDrag == l" :dragType="itemDrag == l?itemDragType:null">
              <span>{{l.index}}</span>
            </slot>

          </DragingEvent>
        </template>
      </template>
      <div v-for="(r,index) in rowData" class="item" style="z-index:100" :style="getRowRightStyle(r)">
        <slot name="rowRight" :data="r" :index="index">
            <!--<span>{{r.setting.name}}</span>-->
        </slot>
      </div>
      <div class="centerLine"></div>
    </div>
  </DragingEvent>
</template>

<script>
  import { uuid } from 'vue-uuid'
  import DragingEvent from './DragingEvent.vue'
  export default {
    components: {
      DragingEvent,
    },
    model: {
      prop: 'values',
      event: 'change'
    },
    props: {
      values: {
        required: true
      },
      option: {
        default: {}
      },
    },
    mounted() {
      if (this.values) {
        this.renewViewData()
      }
    },
    data() {
      return {
        start: -5000,
        end: 5000,
        viewData: [],
        rowData: {},
        sumData: {
          maxL: 0, minStart: 0, maxEnd: 10000
        },
        maxEnd: null,
        minStart: null,
        dragDelayTimer: null,
        itemMoveD: null,
        itemDrag: null,
        itemDragData: null,
        itemDragType: null,
        rulerStep: null,
        rulerMarts: [],
      }
    },
    computed: {
      startName() {
        return this.option.startName || 'start'
      },
      endName() {
        return this.option.endName || 'end'
      },
      rowSettings() {
        var settings = []
        var df = false
        for (var i in this.option.rowSettings) {
          var s = this.newRowSetting(this.option.rowSettings[i])
          settings.push(s)
          if (s.name == 'default') {
            df = true
          }
        }
        if (!df) {
          settings.push(this.newRowSetting({ name: 'default', minH: 0 }))
        }
        return settings
      },
      touchClickDistance() {
        return this.option.touchClickDistance || 10
      },
      touchClickDelay() {
        return this.option.touchClickDelay || 500
      },
      durationName() {
        return this.option.durationName
      },
      minDuration() {
        return this.option.minDuration
      },
      maxDuration() {
        return this.option.maxDuration
      },
      minZoom() {
        return this.option.minZoom || 0
      },
      lineHeight() {
        return Number(this.option.lineHeight) || 20
      },
      dragDelay() {
        return this.option.dragDelay
      },
      beforeDrag() {
        return this.option.beforeDrag
      },
      canDrop() {
        return this.option.canDrop
      },
      getRowName() {
        return this.option.getRowName
      },
      ruler() {
        return this.option.ruler || {}
      },
      rulerStepTable() {
        return this.ruler.stepTable || [100, 200, 500, 1000, 2000, 5000, 10000, 30000, 60000, 300000, 600000]
      },
    },
    watch: {
      values(val, oldval) {
        this.renewViewData(val)
      }
    },
    methods: {
      click(e) {
        console.log('line Click', e)
      },
      getCenter() {
        return (this.end + this.start) / 2
      },
      getRatio() {
        var length = this.end - this.start
        var width = this.$el.offsetWidth
        return length / width
      },
      renewRulerMarts() {
        var length = this.end - this.start
        var width = this.$el.offsetWidth
        var lm = 45
        var i = 0
        var step = this.rulerStepTable[0]
        while (lm / step > width / length) {
          i++
          if (i == this.rulerStepTable.length) {
            break
          }
          step = this.rulerStepTable[i]
        }

        var m = []
        for (var i = 0; i < this.maxEnd + step; i = i + step) {
          if (i >= this.minStart) {
            m.push(i)
          }
        }
        this.rulerMarts = m
        this.rulerStep = step
        return m
      },
      chackLimit() {
        //var length = this.end - this.start
        //var width = this.$el.offsetWidth
        //var lm = 0.1 * length
        //var d
        //var mel = this.maxEnd + length - lm
        //var msl = -lm
        //if (this.end > mel && this.start < msl) {
        //  this.end = mel
        //  this.start = msl
        //} else if (this.end > mel) {
        //  d = this.end - (mel)
        //  this.end -= d
        //  this.start -= d
        //} else if (this.start < msl) {
        //  d = msl - this.start
        //  this.start += d
        //  this.end += d
        //}
        var md = (this.start + this.end) / 2
        if (md < 0) {
          this.end += -md
          this.start += -md
        }
        if (md > this.maxEnd) {
          var d = this.maxEnd - md
          this.end += d
          this.start += d
        }

        this.renewRulerMarts()
      },
      lineWheel(e) {
        this.lineZoom(this.$el.offsetWidth / 2, -e.wheelDeltaY / 1000)
      },
      lineZoom(x, zoom) {
        if (!this.$el) return
        var length = this.end - this.start
        var width = this.$el.offsetWidth
        var p = x - this.$el.clientLeft
        var pp = p / width
        var pl = this.start + pp * length
        var pleft = pl - this.start
        var pRight = this.end - pl
        console.log('lineZoom', pl, pleft, zoom)
        this.start = pl - (pleft * (1 + zoom))
        this.end = pl + (pRight * (1 + zoom))
        this.chackLimit()
      },
      lineMoveing({ start, last, now }) {
        var mx = now.x - last.x

        var length = this.end - this.start
        var width = this.$el.offsetWidth
        this.start += length * (-mx) / width
        this.end += length * (-mx) / width

        this.chackLimit()
        this.$emit('lineMoved', this.start, this.end)
      },
      lineClick() {
        this.$emit('lineClick')
      },
      itemClick(e, l, r) {
        console.log(e, l.data)
        if (this.lineClickTimer) {
          clearTimeout(this.lineClickTimer)
          this.lineClickTimer = null
        }
        this.$emit('itemSelected', l.data)
      },
      itemMoveStart(start, l, r) {
        this.itemMoveD = 0
        if (r.setting.dragable && this.beforeDrag ? this.beforeDrag(l) : true) {
          this.dragDelayTimer = setTimeout(() => {
            this.itemDragStart(l, start.x, start.y, 'move')
            this.dragDelayTimer = null
          }, this.dragDelay)
          //this.itemDragStart(l, px, py, 'move')
          //e.stopPropagation()
        }
      },
      itemDragStart(level, px, py, type) {
        if (!this.itemDrag) {
          this.itemDrag = level
          this.itemDragData = { x: px, y: py, start: level.start, end: level.end, level: level.level, rowGuid: level.rowGuid, height: this.getHeight(this.rowData[level.rowGuid]) }
          //e.target.
          this.itemDragType = type
          this.dragDelayTimer = null
        }
      },
      itemMoveing({ start, last, now }, l, r) {
        var mx = now.x - last.x
        var my = now.y - last.y
        this.itemMoveD += Math.sqrt((mx * mx) + (my * my))
        if (this.itemDrag == l) {
          this.itemDarging(now.x, now.y)
          return
          // this.$emit('itemMoved', l.data, moveX)
        }
        console.log('itemMoveing D', this.itemMoveD)
        if (this.itemMoveD > this.touchClickDistance && this.dragDelayTimer) {
          clearTimeout(this.dragDelayTimer)
          this.dragDelayTimer = null
        }
        if (this.dragDelayTimer == null) {
          this.lineMoveing({ start, last, now })
        }
      },
      itemDarging(px, py, type) {
        var mx = px - this.itemDragData.x
        var my = py - this.itemDragData.y
        var length = this.end - this.start
        var width = this.$el.offsetWidth
        var moveX = length * (mx) / width
        var d = this.itemDrag
        console.log(d, d.start, moveX, px, this.itemDragData.x, length, width)
        switch (type || this.itemDragType) {
          case 'move':
            d.start = this.itemDragData.start + moveX
            d.end = this.itemDragData.end + moveX
            if (d.start < 0) {
              var dx = -d.start
              d.start += dx
              d.end += dx
            }
            var rh = this.getHeight(this.rowData[this.itemDragData.rowGuid])
            console.log(rh)
            var grbh = this.getRowByHeight(rh + my)
            console.log('rowGuid', grbh)
            if (grbh) {
              if (this.canDrop ? this.canDrop(this.itemDrag, grbh.row) : true) {
                d.rowGuid = grbh.row.guid
                d.level = grbh.level
              }
            }
            break
          case 'left':
            d.start = this.itemDragData.start + moveX
            if (d.start > d.end) {
              d.start = d.end
            }
            if (d.start < 0) {
              d.start = 0
            }
            break
          case 'right':
            d.end = this.itemDragData.end + moveX
            if (d.end < d.start) {
              d.end = d.start
            }
            break
          default:
        }
      },
      itemMoveEnd(e, l, r) {
        if (this.itemDrag == l) {
          this.itemDargEnd()
        }
        this.itemMoveD = null
        if (this.dragDelayTimer) {
          clearTimeout(this.dragDelayTimer)
          this.dragDelayTimer = null
        }
      },
      itemDargEnd() {
        console.log('itemDargEnd')
        this.$emit('itemMoved', this.itemDrag.data, this.itemDrag, this.rowData[this.itemDrag.rowGuid], this.itemDragType)
        this.itemDrag = null
        this.itemDragData = null
        this.itemDragType = null
      },
      newRowSetting(setting) {
        return {
          name: 'default',
          key: null,
          value: null,
          minH: 1,
          ...setting
        }
      },
      machSetting(data, setting) {
        if (this.getRowName) {
          var rn = this.getRowName(data) || 'default'
          return rn == setting.name
        } else {
          return data[setting.key] == setting.value
        }
      },
      getLayerStyle(r) {
        return {
          height: `${(this.lineHeight * (r.maxH))}px`,
        }
      },
      getRowDataByIndex(index) {
        for (var i in this.rowData) {
          if (this.rowData[i].index == index) {
            return this.rowData[i]
          }
        }
        return null
      },
      getRowByHeight(h) {
        var i = 0
        var r = this.getRowDataByIndex(i)

        var ch = 0
        while (r) {
          var lineHeight = this.lineHeight
          if (r && r.setting && !isNaN(Number(r.setting.lineHeight))) {
            lineHeight = Number(r.setting.lineHeight)
          }
          var rsh = this.getHeight(r, 0)
          var reh = this.getHeight(r, r.maxH)
          if (h >= rsh && h < reh) {
            var d = h - rsh
            return { row: r, level: Math.floor(Number(d / lineHeight)) }
          }

          i++
          r = this.getRowDataByIndex(i)
        }
        return null
      },
      getHeight(r, level) {
        var lineHeight = this.lineHeight
        if (r && r.setting && !isNaN(Number(r.setting.lineHeight))) {
          lineHeight = Number(r.setting.lineHeight)
        }
        var h = 0;
        var l = level || 0
        for (var i in this.rowData) {
          var rd = this.rowData[i]
          if (rd.index == r.index - 1) {
            h += this.getHeight(rd, rd.maxH)
          }
        }
        return h + (l * lineHeight)
      },
      getLevelStyle(l, r) {
        //console.log(r, this.rowData[l.rowGuid])
        var lineHeight = this.lineHeight
        var length = this.end - this.start
        var width = 100// this.$el.offsetWidth
        return {
          top: `${this.getHeight(r, l.level)}px`,
          left: `${width * (l.start - this.start) / length}%`,
          width: `${width * (l.end - l.start) / length}%`,
          height: `${lineHeight}px`,
        }
      },
      getRowRightStyle(r) {
        var lineHeight = this.lineHeight
        return {
          top: `${this.getHeight(r)}px`,
          right: `0px`,
          height: `${lineHeight * r.maxH}px`,
        }
      },
      getRowBackStyle(r) {
        var lineHeight = this.lineHeight
        return {
          top: `${this.getHeight(r)}px`,
          right: `0px`,
          left: `0`,
          height: `${lineHeight * r.maxH}px`,
        }
      },
      getRulerMartStyle(r) {
        if (!this.$el) return
        var length = this.end - this.start
        var width = 100//this.$el.offsetWidth
        return {
          left: `${width * (r - this.start) / length}%`,
        }
      },
      getRulerStyle(r) {
        return {
          height: `${this.lineHeight}px`,
        }
      },
      renewViewData(data) {
        if (this.lineMoveP) {
          return
        }
        var rows = []
        var levelsrows = {}
        var allLevels = []
        var datas = data || this.values
        var allMaxEnd = null;
        var allMinStart = null;
        for (var id in datas) {
          var nd = false
          for (var ir in this.rowSettings) {
            if (this.machSetting(datas[id], this.rowSettings[ir])) {
              if (!levelsrows[ir]) {
                levelsrows[ir] = []
              }
              levelsrows[ir].push(datas[id])
              nd = true
              break
            }
          }
          if (nd == false && this.rowSettings[ir].name == 'default') {
            levelsrows[ir].push(datas[id])
          }
        }
        console.log(levelsrows)
        for (var ir in this.rowSettings) {
          var guid = uuid.v4()
          var levels = [], maxL = 0, maxH = this.rowSettings[ir].minH, minStart = 0, maxEnd = 0
          if (levelsrows[ir]) {
            var rl = this.rankLevels(levelsrows[ir])
            levels = rl.levels
            maxL = rl.maxL
            if (rl.maxH > maxH) {
              maxH = rl.maxH
            }
            minStart = rl.minStart
            maxEnd = rl.maxEnd
            for (var il in rl.levels) {
              rl.levels[il].rowGuid = guid
              allLevels.push(rl.levels[il])
            }
          }
          rows.push({
            guid,
            levels, maxL, maxH, minStart, maxEnd, setting: this.rowSettings[ir], index: Number(ir)
          })
          if (minStart < allMinStart || allMinStart == null) {
            allMinStart = minStart
          }
          if (maxEnd > allMaxEnd || allMaxEnd == null) {
            allMaxEnd = maxEnd
          }
        }
        this.viewData = allLevels
        var rowData = {}
        var sumData = { maxL: 0, maxH: 0, minStart: allMinStart, maxEnd: allMaxEnd }
        for (var i in rows) {
          console.log(rows[i])
          rowData[rows[i].guid] = rows[i]
          sumData.maxL += rows[i].maxL
          sumData.maxH += rows[i].maxH
        }
        this.rowData = rowData
        this.sumData = sumData
        this.minStart = allMinStart
        this.maxEnd = allMaxEnd
        this.renewRulerMarts()
      },
      rankLevels(values) {
        //var datas = this.sortLevels(this.cutLevels(this.getLevels(values), this.start, this.end))
        var datas = this.sortLevels(this.getLevels(values))
        var levels = []
        var maxL = 0
        var maxH = 0
        var maxEnd = null;
        var minStart = null;
        for (var i in datas) {
          var d = datas[i]
          var c = this.cutLevels(levels, d.start, d.end)
          var l = this.getAbleLevel(c)
          if (l > maxL) {
            maxL = l
          }
          if (l + 1 > maxH) {
            maxH = l + 1
          }
          d.level = l
          d.height = l + 1
          levels.push(d)
          if (d.start < minStart || minStart == null) {
            minStart = d.start
          }
          if (d.end > maxEnd || maxEnd == null) {
            maxEnd = d.end
          }
        }
        console.log('maxL', maxL)
        return {
          levels,
          maxL,
          maxH,
          minStart,
          maxEnd,
        }
      },
      getLevels(values) {
        var l = []
        var vs = values || this.values
        for (var i in vs) {
          var d = vs[i]
          if (this.durationName) {
            l.push({ data: d, start: d[this.startName], end: Number(d[this.startName]) + Number(d[this.durationName]), level: null, index: i, rowGuid: null })
          } else {
            l.push({ data: d, start: d[this.startName], end: d[this.endName], level: null, index: i, rowGuid: null })
          }
        }
        return l
      },
      cutLevels(values, start, end) {
        var rvs = []
        for (var i in values) {
          var v = values[i]
          if (typeof v == "number") {
            if (v > start && v < end) {
              rvs.push(v)
            }
          } else if (typeof v == "object") {
            if (v.start < end && v.end > start) {
              rvs.push(v)
            }
          }
        }
        return rvs
      },
      getAbleLevel(levels) {
        var ls = []
        for (var i in levels) {
          var l = levels[i].level
          if (ls.indexOf(l) == -1) {
            ls.push(l)
          }
        }
        ls = ls.sort()
        if (ls.length == 0) {
          return 0
        }
        for (var i = 0; i < ls.length; i++) {
          if (ls[i] != i) {
            return i
          }
        }
        return ls.length
      },
      sortLevels(values) {
        return values.sort((a, b) => {
          return a.start - b.start
        })
      }
    },
  }
</script>

<style lang="css" scoped>
  .lineBar {
    position: relative;
    /*    height: 80px;
  */ overflow: hidden;
  }

  .layer {
    position: relative;
  }

    .layer .item {
      position: absolute;
    }

  .ruler {
    position: relative;
  }

    .ruler .item {
      position: absolute;
      border-left: 1px solid #fff;
      bottom: 0;
    }

  .centerLine {
    position: absolute;
    left: calc(50%);
    width: 1px;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 0px 5px 1px #333;
  }
</style>
