<template lang="html">
  <div class="DragingEvent" @click="onclick" @mousedown="onmousedown" @mousemove=";" @mouseup=";" @dragstart="ondragstart" @drag="ondrag" @dragend="ondragend" @touchstart="ontouchstart" @touchmove="ontouchmove" @touchend="ontouchend" @touchcancel="touchcancel" @mousewheel="onmousewheel">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      stop: { default: false },
      touchClickDelay: { default: 500 },
      touchClickDistance: { default: 10 },
    },
    mounted() {
    },
    data() {
      return {
        stdata: null,
        ltdata: null,
        moveDistance: null,
        touchs: {},
        clickTimer: null,
        animationFrameDelay: null,
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
      onclick(e) {

      },
      onmousewheel(e) {
        this.$emit('mousewheel', e)
      },
      onmousedown(e) {
        e.preventDefault()
        if (this.stop) {
          e.stopPropagation()
        }
        console.log('onmousedown')
        this.MoveStart(this.mouseEventToData(e))
        document.addEventListener('mousemove', this.onmousemove)
        document.addEventListener('mouseup', this.onmouseup)
      },
      onmousemove(e) {
        e.preventDefault()
        //if (this.stop) {
        //  e.stopPropagation()
        //}
        console.log('onmousemove')
        this.Moveing(this.mouseEventToData(e))
      },
      onmouseup(e) {
        e.preventDefault()
        //if (this.stop) {
        //  e.stopPropagation()
        //}
        console.log('onmouseup')
        document.removeEventListener('mousemove', this.onmousemove)
        document.removeEventListener('mouseup', this.onmouseup)
        this.MoveEnd(this.mouseEventToData(e))
      },
      ondragstart(e) {

      },
      ondrag(e) {

      },
      ondragend(e) {

      },
      ontouchstart(e) {
        e.preventDefault()
        if (this.stop) {
          e.stopPropagation()
        }
        this.MoveStart(this.touchEventToData(e))
        var ts = []
        for (var i in e.changedTouches) {
          var t = e.changedTouches[i]
          this.touchs[t.identifier] = {
            start: t,
            last: t,
            now: t,
          }
          ts.push(this.touchs[t.identifier])
        }
        if (ts.length > 0) {
          this.ToushsStart(ts)
        }
      },
      ontouchmove(e) {
        e.preventDefault()
        if (this.stop) {
          e.stopPropagation()
        }
        this.Moveing(this.touchEventToData(e))

        var ts = []
        for (var i in e.changedTouches) {
          var t = e.changedTouches[i]
          this.touchs[t.identifier].last = this.touchs[t.identifier].now
          this.touchs[t.identifier].now = t
          ts.push(this.touchs[t.identifier])
        }
        if (ts.length > 0) {
          this.ToushsMove(ts)
        }

      },
      ontouchend(e) {
        e.preventDefault()
        if (this.stop) {
          e.stopPropagation()
        }
        this.MoveEnd(this.touchEventToData(e))

        var ts = []
        for (var i in e.changedTouches) {
          var t = e.changedTouches[i]
          this.touchs[t.identifier].last = this.touchs[t.identifier].now
          this.touchs[t.identifier].now = t
          ts.push(this.touchs[t.identifier])
        }
        if (ts.length > 0) {
          this.ToushsEnd(ts)
        }
      },
      touchcancel(e) {
        e.preventDefault()
        if (this.stop) {
          e.stopPropagation()
        }
        this.MoveEnd(this.touchEventToData(e))

        var ts = []
        for (var i in e.changedTouches) {
          var t = e.changedTouches[i]
          this.touchs[t.identifier].last = this.touchs[t.identifier].now
          this.touchs[t.identifier].now = t
          ts.push(this.touchs[t.identifier])
        }
        if (ts.length > 0) {
          this.ToushsEnd(ts)
        }
      },
      touchEventToData(e) {
        //return {
        //  x: e.touches[0].screenX,
        //  y: e.touches[0].screenY
        //}
        return {
          type: 'touch',
          event: e,
          x: e.changedTouches[0].screenX,
          y: e.changedTouches[0].screenY
        }
      },
      mouseEventToData(e) {
        return {
          type: 'mouth',
          event: e,
          x: e.screenX,
          y: e.screenY
        }
      },
      Click() {
        console.log('click')
        this.$emit('click')
      },
      MoveStart(tdata) {
        this.$emit('moveStart', tdata)
        this.stdata = tdata
        this.ltdata = tdata
        this.moveDistance = 0
        this.clickTimer = setTimeout(() => { this.clickTimer = null }, this.touchClickDelay)
      },
      Moveing(tdata) {
        if (!this.stdata || !this.ltdata) {
          return
        }
        this.$emit('moveing', { start: this.stdata, last: this.ltdata, now: tdata })
        var mx = this.stdata.x - tdata.x
        var my = this.stdata.y - tdata.y
        this.moveDistance += Math.sqrt((mx * mx) + (my * my))
        if (this.lineMoveD > this.touchClickDistance && this.clickTimer) {
          clearTimeout(this.clickTimer)
          this.clickTimer = null
        }
        this.ltdata = tdata
      },
      MoveEnd(tdata) {
        this.$emit('moveEnd', { start: this.stdata, last: this.ltdata, now: tdata })
        if (this.clickTimer) {
          clearTimeout(this.clickTimer)
          this.Click()
        }
        this.stdata = null
        this.ltdata = null
        this.moveDistance = null
      },
      ToushsStart(ts) {
        this.$emit('toushsStart', ts)
      },
      ToushsMove(ts) {
        this.$emit('toushsMove', ts)
      },
      ToushsEnd(ts) {
        this.$emit('toushsEnd', ts)
      },
      setAnimationFrameDelay() {
        if (this.animationFrameDelay) {
          return false
        }
        this.animationFrameDelay = requestAnimationFrame(() => {
          this.animationFrameDelay = null
          return true
        })
      }
    },
  }
</script>

<style lang="css" scoped>
  .DragingEvent {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
