<template>
  <div class="ColorPicker">
    <label class="bg colorbtn">
      <span :style="{'background-color':value}"></span>
      <input type="color" v-model="color" @change="colorChange()" style="position:absolute; clip:rect(0 0 0 0);" />
    </label>
    <label class="t">透明度</label>
    <input type="range" v-model.lazy="alpha" min="0" max="255" step="1" @change="colorChange()" list="tickmarks" />
    <datalist id="tickmarks">
      <option value="0" label="0%"></option>
      <option value="15"></option>
      <option value="30"></option>
      <option value="45"></option>
      <option value="60"></option>
      <option value="75"></option>
      <option value="90"></option>
      <option value="105"></option>
      <option value="120" label="50%"></option>
      <option value="135"></option>
      <option value="150"></option>
      <option value="165"></option>
      <option value="180"></option>
      <option value="195"></option>
      <option value="210"></option>
      <option value="225"></option>
      <option value="240"></option>
      <option value="255" label="100%"></option>
    </datalist>
  </div>
</template>

<script>

  export default {
    name: 'ColorPicker',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        type: String
      },
    },
    data() {
      return {
        color: null,
        alpha: null,
      }
    },
    methods: {
      changeColor(color) {
        var arr
        if (arr = color.match(/#[0-9a-f]{8}/)) {
          var c = arr[0]
          this.color = c.match(/#[0-9a-f]{6}/)[0]
          this.alpha = Number(`0x${c.match(/[0-9a-f]{2}$/)[0]}`)
        } else if (arr = color.match(/#[0-9a-f]{6}/)) {
          var c = arr[0]
          this.color = c
          this.alpha = 255

        } else if (arr = color.match(/#[0-9a-f]{4}/)) {
          var c = arr[0]
          console.log(c)
          this.color = `#${c[1]}${c[1]}${c[2]}${c[2]}${c[3]}${c[3]}`
          var a = c.match(/[0-9a-f]{1}$/)[0]
          this.alpha = Number(`0x${a}${a}`)

        } else if (arr = color.match(/#[0-9a-f]{3}/)) {
          var c = arr[0]
          this.color = `#${c[1]}${c[1]}${c[2]}${c[2]}${c[3]}${c[3]}`
          this.alpha = 255
        } else {
          this.color = '#000000'
          this.alpha = 0
        }
      },
      colorChange() {
        var sa = Number(this.alpha).toString(16)
        if (sa.length == 1) {
          sa = '0' + sa
        }
        var color = `${this.color}${sa}`
        this.$emit('change', color)
        return color
      }
    },
    mounted() {
      this.changeColor(this.value)
    },
    watch: {
      value(val) {
        this.changeColor(val)
      }
    }
  }
</script>

<style scoped>
  .ColorPicker {
    display: flex;
    align-items: flex-start;
  }

    .ColorPicker .t {
      margin: 0 5px;
    }

  .bg {
    background: #eee;
    background-image: linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0),linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0);
    background-size: 16px 16px;
    background-position: 0 0,8px 8px;
  }

  .colorbtn {
    padding: 0;
    margin: 0;
    line-height: 0;
    display: inline-block;
    position: relative;
    width: 50px;
    height: 25px;
    vertical-align: top;
    border: solid 3px white;
    border-radius: 4px;
  }

    .colorbtn span {
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
</style>
